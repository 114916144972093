
<template>
  <v-menu v-if="comment.text"
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
        elevation="12"

          v-bind:color="comment.text? 'primary' : 'grey lighten-3'"
          fab x-small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="!comment.text" color="grey darken-3">mdi-comment</v-icon><v-icon v-else>mdi-comment-text</v-icon>
        </v-btn>
      </template>

      <v-card>
        <div class="d-flex justify-space-between">
          <div class="ma-2">Региональные особенности</div><v-spacer/>
<v-btn @click="menu=false" icon class="ma-0"><v-icon>mdi-close</v-icon></v-btn>
</div>
<v-divider/>
<v-card-text>
  {{comment.text}}
</v-card-text>
<v-divider/>

      </v-card>
    </v-menu>
</template>

<script>
export default {
    props:['comment'],
    data(){
        return {
          menu:false
        }
    }
}
</script>

<style>

</style>